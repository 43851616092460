import { APP_VERSION, ENV_VARIABLE } from './utils/config'

export default function App() {
  return (
    <div>
      <h1>
        APP_VERSION: <span id="appversion">{APP_VERSION}</span>
      </h1>
      <h1>
        ENV_VARIABLE: <span id="envvariable">{ENV_VARIABLE}</span>
      </h1>
    </div>
  )
}
